import React, { Component, useState } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import { FaPaperPlane, FaViber, FaPhone } from "react-icons/fa";
import { Modal, Button } from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import Cookies from 'js-cookie';
const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--15',
        category: '',
        title: 'Автокоміс',
        description: 'Допоможемо продати авто у Львові швидко та вигідно!',
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--12',
        category: '',
        title: 'Не вистачає часу?',
        description: 'Якщо не вистачає часу займатися продажем самостійно Black Car Lviv — комісійний продаж легкових автомобілів. Ми знаємо, як продати ваш автомобіль швидко та вигідно.',
    }
]
class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                {SlideList.map((value , index) => (
                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        {value.title ? <h1 className="title bold theme-gradient">{value.title}</h1> : ''}
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        <div className="slidebutton"><Example /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
  }
}
export default SliderOne;


function Example() {

function onChange(e){
    this.setState({
      [e.target.id] : e.target.value,
    });
}
let history = useHistory();
function sendRequest(event){
       event.preventDefault();
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken') },
        body: JSON.stringify({
"phone": phoneValue,
"name": nameValue,
 })
    };
    fetch('https://blackcar.com.ua/api/ru/home/addOrder/', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
   if (phoneValue && nameValue !== '' ){
   setShowSuccsess(prevValue => !prevValue);
   history.push('/success');
   }else{
       setShowError(prevValue => !prevValue);
   }
   }
    function handleNameChange(event) {
        setNameValue(event.target.value);
    }
    function handlePhoneChange(event) {
        setPhoneValue(event.target.value);
    }
  const [show, setShow] = useState(false);
  const [nameValue, setNameValue] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [showTel, setShowTel] = React.useState(false);
  const [showSuccess, setShowSuccsess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  return (
    <>
      <Button variant="white" className="rn-btn" onClick={() => setShow(true)}>
        Зв'яжись з нами
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Ми на зв'язку!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p>
             <div className={`d-flex socailities ${showTel === true ? 'nodisplayer': 'displayer'}`}>
			  <p>В якому месенджері Вам зручніше спілкуватись?</p>
              <a href="https://t.me/blackcaroffice" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-tg"><FaPaperPlane/> Telegram</a>
              <a href="viber://chat/?number=%2B380688077711" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-viber"><FaViber/> Viber</a>
			  <a className="text-center rn-button-style--2 btn-solid " onClick={() => setShowTel(true)}>Зателефонуйте мені</a>
			  <a class="text-center rn-button-style--2 btn-solid bg-dark border-dark mt-1" href="tel:+380688077711">+38 068 80 777 11</a>
            </div>
			 <div className={`d-flex socailities ${showTel === true ? 'displayer': 'nodisplayer'}`}>
         <p>Залиште свій номер і ми перезвонимо Вам якнайшвидше!</p>
           <form className="d-flex flex-column">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={ handleNameChange }
                                            placeholder="Ваше ім'я *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="tel"
                                            name="tel"
                                            id="phone"
                                            onChange={ handlePhoneChange }
                                            placeholder="Ваш телефон *"
                                        />
                                    </label>
                        <div className="socailities">
                                    <button className="rn-button-style--2 text-center m0auto btn-solid" onClick={sendRequest} id="mc-embedded-subscribe">Залишити заявку</button><br />
                                    <span className="rn-button-style--2 text-center mt-20 m0auto btn-outline" onClick={() => setShowTel(false)}>Інший спосіб</span>
                                    <div className={`success ${showSuccess === true ? 'displayer': 'nodisplayer'}`}> Дякуємо, ваша заявка відрпавлена</div>
                                    <div className={`error ${showError === true ? 'displayer': 'nodisplayer'}`}> Ви не заповнили всі поля</div>
                              </div>  </form></div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

