import React, { Component } from "react";
import {IoIosStarOutline , IoIosCog, IoIosColorWand , IoIosBarcode } from "react-icons/io";

const ServiceList = [
    {
        icon: <IoIosStarOutline />,
        title: 'Експертність',
        description: 'Команда експертів по відновленню і обслуговуванню автомобілів, завжди раді потурбуватись про Ваш автомобіль в майбутньому.'
    },
    {
        icon: <IoIosCog />,
        title: 'Кваліфікована допомога',
        description: 'Якщо клієнт не взмозі сам оцінити ступінь пошкоджень та складність ремонту, ми можемо залучити фахівця рихтувального цеху для консультації.'
    },
    {
        icon: <IoIosBarcode />,
        title: 'ОПТ для ВСІХ',
        description: 'Вперше на ринку доставки авто з США компанія Black Car пропонує доставку авто по принципу ОПТ для ВСІХ.'
    },
    {
        icon: <IoIosColorWand />,
        title: '+50 до удачі',
        description: 'Ми прикладемо максимум зусиль щоб виграти вигідний лот для нашого клієнта та зробимо комплексну перевірку історії обраного лоту.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Чому ми?',
        description = 'Оцініть очевидні переваги Black Car, та замовте своє омріяне авто зі Штатів.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="#contactus"><span className="text">Замовити авто</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
