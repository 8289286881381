import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Helmet from "../component/common/Helmet";
import Image1 from "../../public/assets/images/bg/compare-chevrolet.jpg";
import Image2 from "../../public/assets/images/bg/compare-mustang.jpg";
import ReactCompareImage from 'react-compare-image';


class Startup extends Component{
    render(){

        return(
            <Fragment>
                <div className="data-v100 v-wrapper">
                <Helmet pageTitle="BlackCar" />
                <Header logoname="logo.png" />
                <div className="data-v100 d-sm-none d-lg-block w-100">
                <ReactCompareImage
                    leftImage={Image2}
                    rightImage={Image1}
                    sliderLineWidth={1}
                    sliderLineColor="#ffffff4f"
                    handleSize="20"
                    sliderPositionPercentage={0.5}
                />
                </div>
                <div className="containter p-absolute-left">
                    <div>
                        <h3>Авто із США</h3>
                    <p>Привеземо будь-яке авто з аукціону.<br /> Діє принцип "ОПТ для ВСІХ"</p>
                       <a className="rn-btn" href="/buy">
                                            <span>Купити авто</span>
                                        </a>
                    </div>
                </div>
                <div className="containter p-absolute-right">
                    <div>
                        <h3>Калькулятор</h3>
                    <p>Розрахуйте кінцеву вартість авто в Україні <br/>з аукціонів IAAI та Copart.<br /></p>
                        <a className="rn-btn float-right" href="/buy#calculate">
                                            <span>Прорахуй сам</span>
                                        </a>
                    </div>
                </div>


                 {/* Start Back To Top */}
                 <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
</div>

            </Fragment>
        )
    }
}
export default Startup;
