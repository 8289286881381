import React, { Component, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {FaYoutube ,FaInstagram ,FaFacebookF ,FaPaperPlane, FaViber, FaPhone } from "react-icons/fa";
import Cookies from "js-cookie";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/BlackCarLviv/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/blackcarlviv/'},
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCWyx3V2cF1Yvncowt-qfD8Q'},
]
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Пора замовити</span>
                                        <h2>Авто своєї <br /> мрії</h2>
                                            <Example />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Швидкі лінки</h4>
                                                <ul className="ft-link">
                                                    <li><a href="#calculate">Калькулятор</a></li>
                                                    <li><a href="#whyus">Чому ми?</a></li>
                                                    <li><a href="#portfolio">Кейси</a></li>
                                                    <li><a href="#contactus">Контакти</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Подзвоніть нам</h4>
                                                <ul className="ft-link">
                                                    <li><a href="tel:+380688077711">+38(068)807-77-11</a></li>
                                                </ul>
                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a target="_blank" href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>BlackCar © 2020 Designed by DSIDE. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;

function Example() {

function onChange(e){
    this.setState({
      [e.target.id] : e.target.value,
    });
}
let history = useHistory();
function sendRequest(event){
       event.preventDefault();
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken')},
        body: JSON.stringify({
"phone": phoneValue,
"name": nameValue,
 })
    };
    fetch('https://blackcar.com.ua/api/ru/home/addOrder/', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
   if (phoneValue && nameValue !== '' ){
   setShowSuccsess(prevValue => !prevValue);
   history.push('/success');
   }else{
       setShowError(prevValue => !prevValue);
   }
   }
    function handleNameChange(event) {
        setNameValue(event.target.value);
    }
    function handlePhoneChange(event) {
        setPhoneValue(event.target.value);
    }
  const [show, setShow] = useState(false);
  const [nameValue, setNameValue] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [showTel, setShowTel] = React.useState(false);
  const [showSuccess, setShowSuccsess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  return (
    <>
      <Button variant="white" className="rn-btn" onClick={() => setShow(true)}>
        Зв'яжись з нами
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Ми на зв'язку!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p>
             <div className={`d-flex socailities ${showTel === true ? 'nodisplayer': 'displayer'}`}>
			  <p>В якому месенджері Вам зручніше спілкуватись?</p>
              <a href="https://t.me/blackcaroffice" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-tg"><FaPaperPlane/> Telegram</a>
              <a href="viber://chat/?number=%2B380688077711" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-viber"><FaViber/> Viber</a>
			  <a className="text-center rn-button-style--2 btn-solid " onClick={() => setShowTel(true)}>Зателефонуйте мені</a>
			  <a class="text-center rn-button-style--2 btn-solid bg-dark border-dark mt-1" href="tel:+380688077711">+38 068 80 777 11</a>
            </div>
			 <div className={`d-flex socailities ${showTel === true ? 'displayer': 'nodisplayer'}`}>
         <p>Залиште свій номер і ми перезвонимо Вам якнайшвидше!</p>
           <form className="d-flex flex-column">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={ handleNameChange }
                                            placeholder="Ваше ім'я *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="tel"
                                            name="tel"
                                            id="phone"
                                            onChange={ handlePhoneChange }
                                            placeholder="Ваш телефон *"
                                        />
                                    </label>
                        <div className="socailities">
                                    <button className="rn-button-style--2 text-center m0auto btn-solid" onClick={sendRequest} id="mc-embedded-subscribe">Залишити заявку</button><br />
                                    <span className="rn-button-style--2 text-center mt-20 m0auto btn-outline" onClick={() => setShowTel(false)}>Інший спосіб</span>
                                    <div className={`success ${showSuccess === true ? 'displayer': 'nodisplayer'}`}> Дякуємо, ваша заявка відрпавлена</div>
                                    <div className={`error ${showError === true ? 'displayer': 'nodisplayer'}`}> Ви не заповнили всі поля</div>
                              </div>  </form></div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

