import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TrackingTwo from "../elements/service/TrackingTwo";
import ServiceThree from "../elements/service/ServiceFour";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import CallAction from "../elements/callaction/CallAction";
import About2 from "../component/HomeLayout/homeOne/About2";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import CatalogWrapper from "../component/HomeLayout/homeOne/CatalogWrapper";
const data = {
    title: "FAQ (Часті запитання)",
    rows: [
        {
            title: "Що означає ''ОПТ для ВСІХ'' і як це працює?",
            content: `Ми створили рівні умови для усіх бажаючих придбати автомобіль з США, незважаючи на те, чи вам потрібен один автомобіль чи декілька.`,
        },
        {
            title: "Кому я оплачую кошти за виграний лот?",
            content:
                "Усі виграні лоти оплачуються безпосередньо в США на рахунок аукціону від вашого імені. При оплаті вказується номер лоту та VIN номер придбаного автомобіля.",
        },
        {
            title: "Чи можуть виникнути додаткові платежі після покупки лоту?",
            content: `Ми гарантуємо, що усі платежі прописані в договорі, будуть незмінними. `,
        },
        {
            title: "Чи потрібно оплачувати всю суму відразу?",
            content: "Оплата вартості вашого авто включає чотири етапи: <ul><li>	Оплата вартості виграного лоту та послуг аукціону.</li><li>	Вартість доставки автомобіля з площадки аукціону в найближчий порт + вартість морського фрахту.</li><li>	Витрати на вигрузку контейнера та доставку автомобіля в Україну з порту Клайпеда в Литві або Бремергафена в Німеччині.</li><li>	Митні платежі та послуги брокера.</li></ul>",
        },{
            title: "Чи можу я стати автомобільним дилером?",
            content: "Компанія Black Car з радістю допоможе новачкам започаткувати свою справу та стати автодилером. Ми навчимо вас усім тонкостям справи та проконсультуємо у будь-якому питанні абсолютно безкоштовно. Окрім цього, ми надамо ексклюзивні умови на придбання перших лотів для вашого нового стартапу.",
        },
        {
            title: "Чи можна доставити автомобіль в іншу область?",
            content: "Так, можемо доставити автомобіль на будь-яку адресу.",
        }
    ],
};
const styles = {
     bgColor: 'transparent',
     titleTextColor: "white",
     rowTitleColor: "white",
     rowContentPaddingTop: "30px",
     rowContentPaddingBottom: "30px",
     rowContentColor: 'grey',
     arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
};
class MainDemo extends Component{
     constructor(props){
        super(props);
        this.state = {
            posts: [],
        };
    }
    async componentDidMount(){
        let url = ('https://blackcar.com.ua/api/pl/blog/getBlogItemsHome/');
		fetch(url)
		.then(response => response.text())
        .then((response) => {
                this.setState({ posts:JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
		this.setState({
			search: 'active',
      });
    }
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div>
            <div className="active-dark">
                <Helmet pageTitle="BlackCar Lviv" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start About Area */}
                <div className="about-area pt--250 about-position-top pb--120 bg_color--1" id="calculate">
                    <About2 />
                </div>
                {/* End About Area */}

                <Footer />
            </div>
            </div>
        )
    }
}
export default MainDemo;
