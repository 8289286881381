import React, { Component } from "react";
import {  FaTelegramPlane, FaViber } from "react-icons/fa";

const ServiceList = [
    {
        icon: <FaTelegramPlane />,
        title: 'Telegram',
        color: '#0088cc',
        description: 'Авто в різних цінових політиках. Ретельно відібрані BlackCar.',
        span: '@blackcarlviv',
        link: 'https://t.me/blackcarlviv'
    },
    {
        icon: <FaViber />,
        title: 'Viber',
        color: '#7360f2',
        description: 'Найкращі лоти для підписників нашого Viber каналу.',
        span: 'Канал в Viber',
        link: 'https://invite.viber.com/?g2=AQAsFgLnWRIEi0wbg6NrDa12mT%2Fqj8WBTfVH9Y28Us5Qo%2Fvt8naqhoyrjdcxa43L'
    }
]

class ServiceFour extends Component{
    render(){
        let title = 'Ще не підписався?',
        description = 'Найцікавіші лоти за найкращими цінами вже у нас на каналах';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h3 className="title">{title}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link} target="_blank">
                                        <div className={"service service__style--2 "+val.title}>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                                <span class="bottomed">{val.span}</span>
                                            </div>
                                        </div>
                                        </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceFour;
