// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

// Create Import File
import './index.scss';

// Dark Home Layout
import DarkMainDemo from './dark/MainDemo';
import DarkMainDemo2 from './dark/MainDemo2';
import DarkMainDemo3 from './dark/MainDemo3';
import DarkMainDemo4 from './dark/MainDemo4';
import ComisDemo from './dark/ComisDemo';
import Catalog from './dark/Catalog';
import catalogDetails from './dark/CatalogDetails';
import SplitMain from './home/SplitMain';

import error404 from "./elements/error404";
import success from "./elements/success";
import blog from "./elements/Blog";
import blogDetails from "./elements/BlogDetails";
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import uk from 'javascript-time-ago/locale/uk'

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WCHKBRT'
}
ReactGA.initialize('UA-174263500-1');
ReactGA.pageview(window.location.pathname + window.location.search);
class Root extends Component{
    render(){
        const api_regex = /^\/api\/.*/
        // if using "/api/" in the pathname, don't use React Router
        if (api_regex.test(window.location.pathname)) {
            return <div /> // must return at least an empty div
        } else {
            return (
                <BrowserRouter basename={'/'}>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={SplitMain}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/sell`} component={ComisDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/catalog`} component={Catalog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/buy`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/calculate`} component={DarkMainDemo3}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/track`} component={DarkMainDemo4}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/servicecalc`} component={DarkMainDemo2}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/:slug`} component={blogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/catalog/:slug`} component={catalogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/success`} component={success}/>
                        <Route path="*" component={error404}/>
                    </Switch>
                </BrowserRouter>
            )
        }
    }
}
TagManager.initialize(tagManagerArgs);
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(uk)
ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();
