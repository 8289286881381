import React, { Component } from "react";
import Slider from "react-slick";
import axios from 'axios';
import { slickDot } from "../../../page-demo/script";
import ModalVideo from "react-modal-video";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Нью Джерсі > Одеса',
        title: 'Ford Kuga'
    },{
        image: 'image-2',
        category: 'Хобоґен > Київ',
        title: 'Smart'
    },{
        image: 'image-3',
        category: 'Сан Франциско > Теребовля',
        title: 'Audi A6'
    },{
        image: 'image-4',
        category: 'Нью Йорк > Київ',
        title: 'Nissan Leaf'
    },{
        image: 'image-5',
        category: 'Блю Бей > Одеса',
        title: 'Subaru Impreza'
    },{
        image: 'image-6',
        category: 'Лос Анджелес > Луцьк',
        title: 'Audi A4'
    }
]

class Testimonials extends Component{
     constructor(props){
        super(props);
        this.state = {
            contacts: '',
            videoid: '',
            isOpen: false
        }
         this.openModal = this.openModal.bind(this)
     }

componentDidMount() {
		fetch("https://blackcar.com.ua/api/pl/home/testimonials/")
		.then(response => response.text())
        .then((response) => {
            this.setState({ contacts: JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
}
    openModal = (e) => {
        this.setState({isOpen: true, videoid: e.target.value})
    }

render(){
        let mylist = Array.from(this.state.contacts);
        let urlstart = "https://blackcar.com.ua/uploads/"

        let title = 'Відгуки клієнтів',
        description = 'Нам надзвичайно приємно, коли клієнти залишаються задоволені';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalVideo className="vid" channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoid} onClose={() => this.setState({isOpen: false})} />
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...slickDot}>
                            {mylist.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <img className="thumbnail" src={urlstart+value.image}/>
                                        {value.ytid ? <button className="video-popup position-top-center zindex5" value={value.ytid} onClick={this.openModal}> </button> : ''}
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <h4> {value.title} </h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonials;
