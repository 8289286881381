import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Чудовий сервіс і легкий виграш аукціонів - це все, що треба знати про Black Car. Дякую за шикарне авто.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Сергій Васильович,</span> Київ</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Спасибо BlackCar, теперь у меня есть Субару, о которой я мечал целые 5 лет! Помогли выбрать лучший вариант и выиграть аукцион.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Анатолій Михайлович, </span> Одеса</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Отличные менеджеры, все удобно и главное быстро! Спасибо за настоящий американский Форд :)</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Андрій Степанович,</span> Харьков.</h6>
                                    </div>
                                </div>
                            </TabPanel>


                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;