import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
const styles = {
     bgColor: 'transparent',
     titleTextColor: "white",
     rowTitleColor: "white",
     rowContentPaddingTop: "30px",
     rowContentPaddingBottom: "30px",
     rowContentColor: 'grey',
     arrowColor: "red",
};
class Blog extends Component{

    render(){
        return(
            <React.Fragment>
                <div  className="active-dark">
                <PageHelmet pageTitle='Блог' />

               <Header headerPosition="header--transparent" color="color-white" logo="logo-light" />
                </div>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Блог'}  />
                {/* End Breadcrump Area */}

                {/* Start Blog Area */}

                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <BlogList />
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area<Pagination /> */}

                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </React.Fragment>
        )
    }
}
export default Blog;
