import React, { Component } from "react";


const ServiceList = [
    {
        icon: '01',
        title: 'Допоможемо оцінити',
        description: 'Наші фахівці допоможуть оцінити технічний стан авто. Ціну доставки можна розрахувати прямо на сайті.'
    },
    {
        icon: '02',
        title: 'ОПТ для ВСІХ!',
        description: 'Оптові ціни без прихованих оплат. Складаємо договір в якому чітко прописані платежі. '
    },
    {
        icon: '03',
        title: 'Доставимо під ключ',
        description: 'Доставка, розмитнення, ремонт, сертифікація та реєстрація авто - по нашій стороні.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row service-mt">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.svg`} width={50} alt={val.title}/>
                                </div>
                                <div className="content">
                                    <h5 className="title fontWeight800 theme-white">{val.title}</h5>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;