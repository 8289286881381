import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ModalVideo from 'react-modal-video';

class TabsTwo extends Component{
      constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        let 
        tab1 = "1. Готуємо",
        tab2 = "2. Фото",
        tab3 = "3. Онлайн",
        tab4 = "4. Майданчик",
        tab5 = "5. Агенти",
        tab6 = "6. СТО",
        tab7 = "7. Документи";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                        <Tab>{tab5}</Tab>
                                        <Tab>{tab6}</Tab>
                                        <Tab>{tab7}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <h4>Передпродажна підготовка автомобілю</h4><p>
Хімчистка, мийка, полірування, детейлінг, дрібний кузовний ремонт.
Все за цінами нижче ринкових.</p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4> Фотографії та відео</h4>
                                          <p>

Професійна фотозйомка автомобілю та безкоштовний відеоогляд. Матеріали залишаються у вас і ви можете скинути їх потенційним покупцям

                                          </p>
                                           <div className="thumbnail position-relative">
                                        <img className="w-100" src="/assets/images/about/about-11.jpg" alt="Service Images"/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='hgGe4-0Wqvs' onClose={() => this.setState({isOpen: false})} />
                <button className="video-popup position-top-center" onClick={this.openModal}> </button>
            </div>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Продаж в онлайні</h4>
                                         <p>
розміщуємо авто на нашому сайті, на інтернет-ресурсах (auto.ria, olx, rst), у соціальних мережах. Також робимо розсилки по нашій базі потенційних покупців: viber та telegram

                                         </p>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Власна автоплощадка</h4>
                                          <p>
Захищена автоплощадка знаходиться на першій лінії кільцевої дороги Львова. Потенційні покупці можуть приїхати та оглянути автомобіль у будь який час

                                          </p>
                                       </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Професійні агенти з продажу</h4>
                                          <p>
Наші досвідчені спеціалісти завжди на звязку і знають, як працювати з клієнтом та продати ваш автомобіль дорожче            </p>
                                       </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Поруч СТО</h4>
                                          <p>Перед покупкою є можливість оглянути автомобіль та швидко зробити висновок для потенційного покупця           </p>
                                       </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Підготовка документів</h4>
                                          <p>Усі юридичні документи згідно до закону та стандартів підготуємо для вашого підпису. Допомога й повний супровід в переоформленні авто в МРЕО</p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;