import React ,{ Component }from "react";
import { FiWatch, FiCalendar , FiSmile , FiUmbrella, FiSliders } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiWatch />,
        title: 'Економите години',
        description: "Якщо у вас щільний робочий графік. Ми продаємо авто, поки ви займаєтеся своїми справами та проводите час із сім'єю. Ми спілкуємося із потенційнимі покупцями, проводимо зустрічі, показуємо авто на автоплощадці, організовуємо увесь процес продажу"
    },
    {
        icon: <FiCalendar/>,
        title: 'Економите століття',
        description: 'Якщо скласти весь час наших спеціалістів з продажу - вийдуть століття. Вони завжди на зв’язку і опрацьовують усі запити. Професійно підходять до продажу. Знають, як розповісти про авто, щоб продати його дорожче'
    },
    {
        icon: <FiSmile />,
        title: 'Економите нерви',
        description: 'Ми гарантуємо бережне ставлення до вашого автомобіля та повний контроль на кожному етапі продажу.'
    },
    { 
        icon: <FiUmbrella />,
        title: 'Економите бюджети',
        description: 'Спеціальні умови для автодилерів, що займаються пригоном та продажем автомобілів. Дізнайтеся, як налагодити стабільний продаж.'
    },
    {
        icon: <FiSliders />,
        title: 'Економите на дрібницях',
        description: 'Хімчистка, мийка, полірування, детейлінг, дрібний кузовний ремонт. Все за цінами нижче ринкових.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>

                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
