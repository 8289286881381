import React, {Component} from 'react'
import Countdown from 'react-countdown';

class CallAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: '',
        }
    }

    componentDidMount() {
        fetch("https://blackcar.com.ua/api/pl/home/promo/")
            .then(response => response.text())
            .then((response) => {
                this.setState({promo: JSON.parse(response)[0]});
            }).catch((err) => {
            console.log('fetch', err)
        });
    }

    render() {
        let promo = this.state.promo;
        let date =  new Date(promo.date_till).getTime();
        return (
            <div className={"call-to-action-wrapper call-to-action text-white-wrapper ptb--120 " + promo.active}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <h2 className="text-white size2">{promo.title} <span className="oldprice position-absolute text-white-50" style={{top:'-50px', fontSize: '.8rem', textDecoration: 'line-through'}}>&nbsp;{promo.old_price}&nbsp;</span><span className="price">{promo.new_price}</span></h2>
                                <span>{promo.descr}</span>
                                {promo.counter && promo.counter ? <h3 className="text-white"><Countdown date={`${this.state.promo.date_till}`} no /></h3>: ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CallAction;
