import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ReactTimeAgo from 'react-time-ago'
import { FiClock , FiUser , FiMessageCircle , FiHeart, FiCalendar } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
            data: '',
            date: '',
        }
        this.openModal = this.openModal.bind(this)
    }
    async componentDidMount(){
        let slug = this.props.match.params.slug;
        let url = ('https://blackcar.com.ua/api/pl/blog/getBlogItemDetails/'+slug);
		fetch(url)
		.then(response => response.text())
        .then((response) => {
                this.setState({ data: JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
		this.setState({
			search: 'active',
      });
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle={`${this.state.data.title}`} />
                 <div  className="active-dark">
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                 </div>
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image" style={{backgroundImage: `url(https://blackcar.com.ua${this.state.data.image})`}} data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-white">{this.state.data.title}</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiCalendar />Дата: {this.state.data.date}</li>
                                        <li><FiClock />Час читання: {this.state.data.w_time} хвилин(и)</li>
                                        <li>Категорія: {this.state.data.category}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>{this.state.data.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;
