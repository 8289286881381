import React, { Component } from "react";
import { FiShield , FiRefreshCcw, FiDollarSign,FiMapPin } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiDollarSign />,
        title: 'Безкоштовно',
        description: 'Ви не сплачуєте комісію з продажу. Вартість послуг додається до вартості авто — її сплачує покупець'
    },
    {
        icon: <FiMapPin />,
        title: 'Зручно',
        description: 'Вигідне розташування площадки для демонстрації вашого авто. Власна автоплощадка на Кільцевій дорозі'
    },
    {
        icon: <FiRefreshCcw />,
        title: 'Трафік потенційних покупців',
        description: 'Автоплощадка знаходиться на першій лінії Кільцевої дороги Львова, довжина площадки вздовж траси складає 90 м і вміщує 100 автомобілів'
    },
    {
        icon: <FiShield />,
        title: 'Цілком безпечно',
        description: 'Автоплощадка під постійним відео-наглядом та охороною. За безпеку авто можна не хвилюватись.'
    }
]

class ServiceThree extends Component{
    render(){
        let title = 'Чому ми?',
        description = 'Оцініть очевидні переваги Black Car, та продайте своє авто з нашою допомогою.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="#contactus"><span className="text">Продати авто</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
