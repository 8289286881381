import React, { Component, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaPaperPlane, FaViber, FaPhone } from "react-icons/fa";
import {useHistory} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {FiCheck, FiAlertCircle, FiInfo} from "react-icons/fi";
import Cookies from 'js-cookie';
class Calculator extends Component{
    constructor(props){
        super(props);
        this.state = {
            delivery: 'kla',
            rnLotID: '',
			search: '',
            rnAuc: 'copart',
            location: '',
            fuel: '',
            error: false,
			name: '',
            vin: '',
            title_message: 'Cконтактуйтесь з менеджером, щоб дізнатись чи можна експортувати це авто',
            saledoc: '',
            offsite: '',
            broker: '200',
            brokerod: '300',
			lotfinalprice: '',
            caryear: '',
            usport: '',
            odport: '',
            vat: '',
            lvport: '',
            odtotal: '',
            lvtotal: '',
			engine: '',
			land: '1',
			landcost: '',
			totalPrice: '',
			lottotal: '0',
            mainimg: '',
			courseeur: '',
			courseusd: '',
            uservalue: '',
			currentyear: '',
            myto: '',
			taxclasssize: '',
			currentprice: '',
			akcyz: '',
			undefcost: false,
			akcyzfinal: '',
			isBoxVisible: false,
			requestStatus: '',
			isInputEmpty: false,
            broker_cost: '',
            export_documents_cost: '',
            expedition_cost: '',
            lithuania_to_ukraine_cost: '',
            blackcar_service_cost: '',
            odessa_broker_and_expeditor: '',
        };
        this.handlerChange = this.handlerChange.bind(this);
    }
handlerChange1(e){
		if (document.getElementById('item01').value === '') {
			this.setState({isInputEmpty: false});
} else {
  this.setState({isInputEmpty: true});
}
}
resetForm(){
   this.setState({
	   courseeur: '',
	   courseusd: '',
	   uservalue: '',
	   currentyear: '',
       location: '',
	   taxclasssize: '',
	   myto: '',
	   akcyz: '',
	   lotfinalprice: '',
	   caryear: '',
	   usport: '',
	   odport: '',
	   vat: '',
	   lvport: '',
	   odtotal: '',
	   lvtotal: '',
	   engine: '',
	   land: '1',
	   landcost: '',
	   totalPrice: '',
	   lottotal: '0',
	   fuel: '',
	   name: '',
	   vin: '',
       broker_cost: '',
       export_documents_cost: '',
       expedition_cost: '',
       lithuania_to_ukraine_cost: '',
       blackcar_service_cost: '',
       odessa_broker_and_expeditor: '',
   })
}
        handlerChange(e){
		if (document.getElementById('item02').value === '') {
			this.setState({isBoxVisible: false});
} else {
  this.setState({isBoxVisible: true});
}
		const currentPrice = e.target.value;
		this.setState({
			currentprice: parseInt(currentPrice),
			lotfinalprice: ((parseInt(currentPrice)+1100)*0.1),
			akcyzfinal: this.state.akcyz/this.state.courseusd,
			vat:  Math.round(((parseInt(currentPrice)+1100) +  ((parseInt(currentPrice)+1100)*0.1) + (this.state.akcyz/this.state.courseusd))*0.2),
			totalPrice: Math.round(((parseInt(currentPrice)+1100)*0.1) + (this.state.akcyz/this.state.courseusd) + Math.round(((parseInt(currentPrice)+1100) +  ((parseInt(currentPrice)+1100)*0.1) + (this.state.akcyz/this.state.courseusd))*0.2)),
		});
	};

    setGender(event) {
	this.setState({rnAuc: event.target.value});
    }
    setDelivery(event) {
	this.setState({delivery: event.target.value});
    }

    sendRequest(event){
        let lot = this.state.rnLotID;
        lot = lot.replace(/\s/g, '');
		this.resetForm();
		let privatUrl = ('https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5');
		fetch(privatUrl)
		.then(response => response.text())
        .then((response) => {
                this.setState({ courseusd:((JSON.parse(response)[0].sale)/(JSON.parse(response)[1].sale)), courseeur:((JSON.parse(response)[1].sale)/(JSON.parse(response)[0].sale))});
        }).catch((err) => {
            console.log('fetch', err)
    	});
		this.setState({
			search: 'active',
      });

		//const proxyurl = "https://cors-anywhere.herokuapp.com/";
		let url = ('https://blackcar.com.ua/api/pl/home/get'+ this.state.rnAuc + '/?stock=' + lot);
		this.setState({ requestStatus: 'Шукаємо лот' });

		trackPromise(fetch(url)
		.then(findresponse => findresponse.text())
    	.then((findresponse)=>{

	    const carCurrentVIN = JSON.parse(findresponse).Vin;
		const years = [1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039];
		const values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
		    let i = 0;
		    let pos7 = carCurrentVIN.charAt(6);
		    let pos10 = carCurrentVIN.charAt(9);
		    if (isNaN(pos7)) {
		        i = values.lastIndexOf(pos10);
		    } else {
		        i = values.indexOf(pos10);
		    }
            const carCurrentYear = years[i];

	    let engine = '0 L';
		if (JSON.parse(findresponse).Engine !== 'null') {
		    engine = JSON.parse(findresponse).Engine;
        } else {
		    engine = '0 L';
        }

      this.setState({
        error: false,
        location: JSON.parse(findresponse).Location.replace(/\s+/g, ''),
    	fuel:JSON.parse(findresponse).Fuel,
        engine:parseFloat((engine).split('L')[0].replace(/[^.0-9]/gim,'').substr(0,3)),
        vin:JSON.parse(findresponse).Vin,
        saledoc:JSON.parse(findresponse).SaleDoc,
        offsite:JSON.parse(findresponse).Offsite,
        name:JSON.parse(findresponse).Name,
        caryear:JSON.parse(findresponse).Year,
        mainimg:JSON.parse(findresponse).Images[0],
      });
		this.setState({currentyear: (2022) - carCurrentYear});
		let pricesUrl = ('https://blackcar.com.ua/api/pl/home/prices/');
		 let locurl = ('https://blackcar.com.ua/api/pl/home/locations/?location=' + this.state.location);
	  fetch(locurl)
		.then(locresponse => locresponse.text())
			.then((locresponse) => {
                const { export_documents_cost, odessa_broker_and_expeditor } = fetch(pricesUrl)
                    .then(priceresponse => priceresponse.text())
                    .then(priceresponse => {
                            this.setState({
                                broker_cost: JSON.parse(priceresponse).broker_cost,
                                export_documents_cost: JSON.parse(priceresponse).export_documents_cost,
                                expedition_cost: JSON.parse(priceresponse).expedition_cost,
                                lithuania_to_ukraine_cost: JSON.parse(priceresponse).lithuania_to_ukraine_cost,
                                blackcar_service_cost: JSON.parse(priceresponse).blackcar_service_cost,
                                odessa_broker_and_expeditor: JSON.parse(priceresponse).odessa_broker_and_expeditor,
                                landcost: JSON.parse(locresponse).land,
                                usport: JSON.parse(locresponse).depport,
                                lvport: JSON.parse(locresponse).lvport,
                                odport: JSON.parse(locresponse).odport,
                                odtotal: ((Number(JSON.parse(locresponse).odport)) + (Number(JSON.parse(locresponse).land)) + (Number(JSON.parse(priceresponse).export_documents_cost))),
                                lvtotal: ((Number(JSON.parse(locresponse).lvport)) + (Number(JSON.parse(locresponse).land)) + (Number(JSON.parse(priceresponse).export_documents_cost))),
                            });
                        }
                    )
                    .catch((err) => {
                    console.log('fetch', err);
                        console.log('ERROR');
                    });

        }).catch((err) => {
          this.setState({ error : true });
            console.log('fetch', err);
            console.log('ERROR');
    	});
	    this.setState({
            search: 'active',
        });


		  if(this.state.landcost === "undefined"){
			  this.setState({undefcost: true})
		  }  else {this.setState({undefcost: false})}

	  if (this.state.engine <= 3 && this.state.fuel !== 'DIESEL'){
      this.setState({taxclasssize: 50});
	  } else if (this.state.engine <= 3.5 && this.state.fuel === 'DIESEL'){
	  this.setState({taxclasssize: 75});
	  } else if (this.state.engine > 3 && this.state.fuel !== 'DIESEL'){
	  this.setState({taxclasssize: 100});
	  } else if (this.state.engine > 3.5 && this.state.fuel === 'DIESEL'){
	  this.setState({taxclasssize: 150});
		};
	  if (this.state.engine < 9){
	  this.setState({
		  akcyz: Math.abs(this.state.taxclasssize * this.state.engine * ((this.state.currentyear > 16 ? 16 : this.state.currentyear) - 1)),
	  });} else {this.setState({
		  akcyz: Math.abs(this.state.taxclasssize * (this.state.engine/10) * ((this.state.currentyear > 16 ? 16 : this.state.currentyear) - 1)),
	  });}
    })
	.catch((error: any) => this.setState({error: true}))
	.catch(function(error) {
 console.log('There has been a problem with your fetch operation: ',
 error.message)},
	this.setState({ requestStatus: 'Шукаємо Ваш лот...' })));



	}

    render(){
        const { tabStyle } = this.props;
 const LoadingIndicator = props => {
   const { promiseInProgress } = usePromiseTracker();
   return (
       <div>
       { (promiseInProgress === true) ?
           <div
               style={{
                   width: "100%",
                   height: "100",
                   display: "flex",
                   justifyContent: "center",
                   alignItems: "center"
               }}
           >
               <Loader type="Rings" color="#F30033" height="100" width="100"/>
               <span className="theme-white">Шукаємо Ваш лот</span>
           </div>
           :
            <div>{ this.state.error === true ?
                <div style={{marginTop: '-100px'}}>
                    <h3> <FiAlertCircle className="text-danger"/> Ой, щось не так! </h3>
                    <p className="text-white">Можливі причини:</p>
                    <ul className="text-white">
                        <li>Ви неправильно ввели номер лоту</li>
                        <li>Аукціон на цей автомобіль же завершено</li>
                        <li>Аукціон {this.state.rnAuc} проводить технічні роботи</li>
                        <li>Ми проводимо технічні роботи з оновлення калькулятора</li>
                    </ul>

                </div>

                : null}</div>
        }
       </div>
  );
 };

        return(

            <div className="contact-form--1" >
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 order-2 order-lg-1 nopadding">
                            <div className="form-wrapper">
                                <div className="formik">
                                    <div><label htmlFor="item01" className=" flb">
                                        <input
                                            type="text"
                                            name="lotid"
                                            id="item01"
                                            onChange={(e)=>{this.setState({rnLotID: e.target.value}); this.handlerChange1();}}
                                            placeholder="Номер лоту"
                                        />
                                    </label>
                                     <div className="worms"  >
	<input id="a" type="radio" className="radios" name="hopping" value="copart" defaultChecked onChange={this.setGender.bind(this)}/>
	<label for="a"><span></span>Copart</label>
	<input id="b" type="radio" className="radios" name="hopping" value="iaai" onChange={this.setGender.bind(this)}/>
	<label for="b"><span></span>IaaI</label>

                                         </div>
</div>
                                    <button disabled={this.state.isInputEmpty === false ? 'disabled': ''} className={`rn-button-style--2 btn-solid ${this.state.isInputEmpty === true ? 'opacity1': 'opacity0'}`} onClick={ event => this.sendRequest(event)}>Розрахувати</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
					<div style={{backgroundColor:'rgb(28 28 28)'}} className={`row flex rounded p-2 mt-50 ${this.state.search === "active"? '': 'displaynone'} ${this.state.name !== ""? '': 'displaynone'}`} >
									<div className="lotimage col-lg-2 "><img className="img-rounded-6" src={this.state.mainimg} width={100}/></div>
									<div className="lotdescr col-lg-7">
										<div className="theme-white"><h4>{this.state.name}</h4></div>
                                        {this.state.offsite ? <div className="text-warning" style={{marginTop: '-15px', fontSize: "12px"}}><span style={{ fontSize: "14px"}}><FiAlertCircle /></span> Автомобіль знаходиться за межами площадки аукціону. Деталі у менеджера.</div> : null }
										<div className="text-warning" style={{ fontSize: "12px"}}><span style={{ fontSize: "14px"}}><FiAlertCircle /></span> {this.state.title_message}</div>
										<div className="theme-white">{'VIN:' + this.state.vin}</div>
                                        <p className=" theme-white" style={{ fontSize: "12px"}}>Розрахунок виконано на сайті <b>blackcar.com.ua</b>
                        </p>
									</div>
                                    <div className="lotdescr col-lg-3">
                                        <span className="theme-white">Доставка через:</span>
                                        <input id="kla" type="radio" className="radios" name="delivery" value="kla"
                                               defaultChecked onChange={this.setDelivery.bind(this)}/>
                                        <label htmlFor="kla"><span></span>Клапейду</label>
                                        <input id="od" type="radio" className="radios" name="delivery" value="od"
                                               onChange={this.setDelivery.bind(this)}/>
                                        <label htmlFor="od"><span></span>Одесу</label>
									</div>
					</div>

					<LoadingIndicator/>
					<div className={`row flex mt-50 bottomer theme-white ${this.state.search === "active"? '': 'displaynone'} ${this.state.name !== ""? '': 'displaynone'}`} >

									<div className="lotpricing col-lg-4 "><label htmlFor="item02" className="flb">
								Вартість лоту, <b>$</b>
                                        <input
                                            type="text"
                                            name="lotprice"
                                            id="item02"
											min="500"
											onChange={this.handlerChange}
                                            placeholder="Повна вартість лоту"
                                        />
                            </label><br /><Example /></div>
								<div className="lotpricing col-lg-3 theme-white">Митні платежі<br /><h4>{`${this.state.totalPrice > 0 ? ('$'+ (this.state.fuel !== 'ELECTRIC' ? this.state.totalPrice : 100)) : '---'}`}</h4>
								<ul className={`pricedetails ${this.state.isBoxVisible === true ? 'opacity1': 'opacity0'}`}>
									У тому числі:
									<li className="meduza">ПДВ 20%: <b>${this.state.fuel !== 'ELECTRIC' ? this.state.vat : 0}</b></li>
									<li className="meduza">Митний збір: <b>${this.state.fuel !== 'ELECTRIC' ? Math.round(this.state.lotfinalprice) : 100}</b></li>
									<li className="meduza">Акцизний збір: <b>${this.state.fuel !== 'ELECTRIC' ? Math.round(this.state.akcyz/this.state.courseusd): 0}</b></li>
								</ul>
								</div>
								<div className="lotpricing col-lg-5 theme-white">
                                    Повна вартість розмитненого авто<br /><h3>$ {`${this.state.totalPrice > 0 ? (this.state.delivery === 'kla' ? ( parseInt(this.state.currentprice) + parseInt(this.state.lvtotal) + parseInt((this.state.fuel !== 'ELECTRIC' ? this.state.totalPrice : 100)) + parseInt(this.state.broker_cost) + parseInt(this.state.blackcar_service_cost) + parseInt(this.state.expedition_cost) + parseInt(this.state.lithuania_to_ukraine_cost)):( parseInt(this.state.currentprice) + parseInt(this.state.odtotal) + parseInt((this.state.fuel !== 'ELECTRIC' ? this.state.totalPrice : 100))  + parseInt(this.state.blackcar_service_cost) + parseInt(this.state.odessa_broker_and_expeditor))) : '---'}`}</h3>
								<ul className={`pricedetails ${this.state.isBoxVisible === true ? 'opacity1': 'opacity0'}`}>
									У тому числі:
                                            <li className="meduza">Оформлення експортних документів: <b>${this.state.export_documents_cost}</b></li>
                                            <li className="meduza">Доставка з {this.state.location} до порту {this.state.usport}: <b>${this.state.landcost}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'kla' ? 'opacity1': 'displaynone2'}`}>Морський фрахт (порт Клайпеда, Литва): <b>${this.state.lvport}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'od' ? 'opacity1': 'displaynone2'}`}>Морський фрахт (порт Одеса, Україна): <b>${this.state.odport}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'kla' ? 'opacity1': 'displaynone2'}`}>Експедиція в порту призначення: <b>${this.state.expedition_cost}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'kla' ? 'opacity1': 'displaynone2'}`}>Доставка з Литви в Україну: <b>${this.state.lithuania_to_ukraine_cost}</b></li>
                                            <li className="meduza">Послуги Black Car: <b>${this.state.blackcar_service_cost}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'kla' ? 'opacity1': 'displaynone2'}`}>Послуги брокера: <b>${this.state.broker_cost}</b></li>
                                            <li className={`meduza ${this.state.delivery === 'od' ? 'opacity1': 'displaynone2'}`}>Брокер та експедитор в порту Одеса: <b>${this.state.odessa_broker_and_expeditor}</b></li>
								</ul>
								</div>
					</div>
            </div>

        )
    }
}
export default Calculator;

function Example() {

function onChange(e){
    this.setState({
      [e.target.id] : e.target.value,
    });
}
let history = useHistory();
function sendRequest(event){
       event.preventDefault();
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken') },
        body: JSON.stringify({
"phone": phoneValue,
"name": nameValue,
 })
    };
    fetch('https://blackcar.com.ua/api/ru/home/addOrder/', requestOptions)
        .then(response => response.json())

   if (phoneValue && nameValue !== '' ){
   setShowSuccsess(prevValue => !prevValue);
   history.push('/success');
   }else{
       setShowError(prevValue => !prevValue);
   }
   }
    function handleNameChange(event) {
        setNameValue(event.target.value);
    }
    function handlePhoneChange(event) {
        setPhoneValue(event.target.value);
    }
  const [show, setShow] = useState(false);
  const [nameValue, setNameValue] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [showTel, setShowTel] = React.useState(false);
  const [showSuccess, setShowSuccsess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  return (
    <>
      <Button variant="white" className="rn-btn" onClick={() => setShow(true)}>
        Зв'яжись з нами
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Ми на зв'язку!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p>
             <div className={`d-flex socailities ${showTel === true ? 'nodisplayer': 'displayer'}`}>
			  <p>В якому месенджері Вам зручніше спілкуватись?</p>
              <a href="https://t.me/blackcaroffice" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-tg"><FaPaperPlane/> Telegram</a>
              <a href="viber://chat/?number=%2B380688077711" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-viber"><FaViber/> Viber</a>
			  <a className="text-center rn-button-style--2 btn-solid " onClick={() => setShowTel(true)}>Зателефонуйте мені</a>
			  <a class="text-center rn-button-style--2 btn-solid bg-dark border-dark mt-1" href="tel:+380688077711">+38 068 80 777 11</a>
            </div>
			 <div className={`d-flex socailities ${showTel === true ? 'displayer': 'nodisplayer'}`}>
         <p>Залиште свій номер і ми перезвонимо Вам якнайшвидше!</p>
           <form className="d-flex flex-column">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={ handleNameChange }
                                            placeholder="Ваше ім'я *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="tel"
                                            name="tel"
                                            id="phone"
                                            onChange={ handlePhoneChange }
                                            placeholder="Ваш телефон *"
                                        />
                                    </label>
                        <div className="socailities">
                                    <button className="rn-button-style--2 text-center m0auto btn-solid" onClick={sendRequest} id="mc-embedded-subscribe">Залишити заявку</button><br />
                                    <span className="rn-button-style--2 text-center mt-20 m0auto btn-outline" onClick={() => setShowTel(false)}>Інший спосіб</span>
                                    <div className={`success ${showSuccess === true ? 'displayer': 'nodisplayer'}`}> Дякуємо, ваша заявка відрпавлена</div>
                                    <div className={`error ${showError === true ? 'displayer': 'nodisplayer'}`}> Ви не заповнили всі поля</div>
                              </div>  </form></div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

