import React, { Component ,Fragment } from "react";
import BlogContent from "./BlogContent";

class BLogList extends Component{
    constructor(props){
        super(props);
        this.state = {
            posts: [],
        };
    }
    async componentDidMount(){
        let url = ('https://blackcar.com.ua/api/pl/blog/getBlogItems/');
		fetch(url)
		.then(response => response.text())
        .then((response) => {
                this.setState({ posts:JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
		this.setState({
			search: 'active',
      });
    }
    render() {
        return (
             <Fragment>
                 <div className="row">
                    {this.state.posts.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={`/blog/${value.slug}`}>
                                        <img className="w-100" src={`https://blackcar.com.ua/${value.thumb}`} alt={value.title}/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.category}</p>
                                    <h4 className="title"><a href={`/blog/${value.slug}`}>{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href={`/blog/${value.slug}`}>Читати повністю</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default BLogList;
