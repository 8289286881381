import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { BiCalculator } from "react-icons/bi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TrackingTwo from "../elements/service/TrackingTwo";
import ServiceThree from "../elements/service/ServiceFour";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import CallAction from "../elements/callaction/CallAction";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Testimonials from "../component/HomeLayout/homeOne/Testimonials";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import CatalogWrapper from "../component/HomeLayout/homeOne/CatalogWrapper";

const styles = {
    bgColor: 'transparent',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentPaddingTop: "30px",
    rowContentPaddingBottom: "30px",
    rowContentColor: 'grey',
    arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
};
class MainDemo extends Component{
    constructor(props){
        super(props);
        this.state = {
            posts: [],
        };
        this.blackCalc = React.createRef();
        this.innerRef = React.createRef();
    }

    scroll = () => {
        let bc = this.blackCalc.current.offsetTop;
        let mb = this.innerRef.current.offsetTop;
        if (window.innerWidth > 600) {
            window.scrollTo({top: bc, behavior: 'smooth'});
        } else {
            window.scrollTo({top: mb-50, behavior: 'smooth'});
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div>
                <div className="active-dark">
                    <Helmet pageTitle="BlackCar Lviv" />
                    <Header bc={this.blackCalc} bm={this.innerRef} headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                    {/* Start Slider Area   */}
                </div> <div className="active-dark">
                {/* End Slider Area   */}
                {/* Start Slider Area   */}
            </div> <div className="active-dark">
                {/* End Slider Area   */}
                {/* Start About Area */}
                <div ref={this.blackCalc} className="about-area pb--120 pt--200 bg_color--1">
                    <About {...this.props} passedRef={this.innerRef}/>
                </div>
                {/* End About Area */}

                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <a onClick={this.scroll} style={{"left": '35px', "right": 'unset',
                        'position': 'fixed',
                        'z-index': '500',
                        'bottom': '50px',
                        'width':' 50px',
                        'height': '50px',
                        'cursor': 'pointer',
                        'line-height': '49px',
                        'border-radius': '50%',
                        'background-color': '#f62847',
                        'text-align': 'center'
                    }}>
                        <BiCalculator />
                    </a>
                </div>
                {/* End Back To Top */}

                <Footer />
            </div>
            </div>
        )
    }
}
export default MainDemo;
