import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { BiCalculator } from "react-icons/bi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TrackingTwo from "../elements/service/TrackingTwo";
import ServiceThree from "../elements/service/ServiceFour";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import CallAction from "../elements/callaction/CallAction";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Testimonials from "../component/HomeLayout/homeOne/Testimonials";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import CatalogWrapper from "../component/HomeLayout/homeOne/CatalogWrapper";
const data = {
    title: "FAQ (Часті запитання)",
    rows: [
        {
            title: "Що означає ''ОПТ для ВСІХ'' і як це працює?",
            content: `Ми створили рівні умови для усіх бажаючих придбати автомобіль з США, незважаючи на те, чи вам потрібен один автомобіль чи декілька.`,
        },
        {
            title: "Кому я оплачую кошти за виграний лот?",
            content:
                "Усі виграні лоти оплачуються безпосередньо в США на рахунок аукціону від вашого імені. При оплаті вказується номер лоту та VIN номер придбаного автомобіля.",
        },
        {
            title: "Чи можуть виникнути додаткові платежі після покупки лоту?",
            content: `Ми гарантуємо, що усі платежі прописані в договорі, будуть незмінними. `,
        },
        {
            title: "Чи потрібно оплачувати всю суму відразу?",
            content: "Оплата вартості вашого авто включає чотири етапи: <ul><li>	Оплата вартості виграного лоту та послуг аукціону.</li><li>	Вартість доставки автомобіля з площадки аукціону в найближчий порт + вартість морського фрахту.</li><li>	Витрати на вигрузку контейнера та доставку автомобіля в Україну з порту Клайпеда в Литві або Бремергафена в Німеччині.</li><li>	Митні платежі та послуги брокера.</li></ul>",
        },{
            title: "Чи можу я стати автомобільним дилером?",
            content: "Компанія Black Car з радістю допоможе новачкам започаткувати свою справу та стати автодилером. Ми навчимо вас усім тонкостям справи та проконсультуємо у будь-якому питанні абсолютно безкоштовно. Окрім цього, ми надамо ексклюзивні умови на придбання перших лотів для вашого нового стартапу.",
        },
        {
            title: "Чи можна доставити автомобіль в іншу область?",
            content: "Так, можемо доставити автомобіль на будь-яку адресу.",
        }
    ],
};
const styles = {
     bgColor: 'transparent',
     titleTextColor: "white",
     rowTitleColor: "white",
     rowContentPaddingTop: "30px",
     rowContentPaddingBottom: "30px",
     rowContentColor: 'grey',
     arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
};
class MainDemo extends Component{
     constructor(props){
        super(props);
        this.state = {
            posts: [],
        };
        this.blackCalc = React.createRef();
        this.innerRef = React.createRef();
     }

    scroll = () => {
        let bc = this.blackCalc.current.offsetTop;
        let mb = this.innerRef.current.offsetTop;
        if (window.innerWidth > 600) {
            window.scrollTo({top: bc, behavior: 'smooth'});
        } else {
            window.scrollTo({top: mb-50, behavior: 'smooth'});
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        let url = ('https://blackcar.com.ua/api/pl/blog/getBlogItemsHome/');
		fetch(url)
		.then(response => response.text())
        .then((response) => {
                this.setState({ posts:JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
		this.setState({
			search: 'active',
      });
    }
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div>
            <div className="active-dark">
                <Helmet pageTitle="BlackCar Lviv" />
                <Header bc={this.blackCalc} bm={this.innerRef} headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
               </div> <div className="slider-wrapper" id="home">
                    <SliderOne />
                </div><div className="active-dark">
                {/* End Slider Area   */}
               {/* Start Slider Area   */}
               </div> <div>
                    <CallAction />
                </div><div className="active-dark">
                {/* End Slider Area   */}
                {/* Start About Area */}
                <div ref={this.blackCalc} className="about-area about-position-top pb--120 bg_color--1">
                    <About {...this.props} passedRef={this.innerRef}/>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="tracking-area ptb--80  bg_image bg_image--3" id="tracking">
                   <div className="container">
                        <TrackingTwo />
                           <iframe scrolling="no" src="https://www.cargoloop.com/Tracking.aspx" width="100%" height="500"/>
                   </div>
                </div>
                {/* End Service Area  */}
                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_image bg_image--3" id="whyus">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--30 bg_color--1" id="portfolio">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Black Car в цифрах</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                End CounterUp Area */}
                {/* Start Catalog Area
                <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6s col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <div className="section-title"><h2>Авто в дорозі та в навності.</h2><p>Для тих хто не вміє чекати ;)</p></div>
                                </div>
                                <div>
                                    <CatalogWrapper />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                End Catalog Area */}
                {/* Start Blog Area
                <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <h2>Блог</h2>
                                    <p>Найцікавіше зі світу авто з США.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">Всі пости</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {this.state.posts.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={`/blog/${value.slug}`}>
                                                <img className="w-100" src={`https://blackcar.com.ua/${value.thumb}`} alt={value.title}/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href={`/blog/${value.slug}`}>{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={`/blog/${value.slug}`}>Читати повністю</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                 End Blog Area */}
                {/* Start Testimonial Area
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                /* End Testimonial Area */}

                {/* Start Service Area
                <div className="service-area ptb--80  bg_image bg_image--3" >
                   <div className="container">
                        <ServiceThree />
                   </div>
                </div>
                 End Service Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Testimonials Area */}
                <div className="portfolio-area ptb--30 bg_color--1" id="portfolio">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Testimonials />
                    </div>
                </div>
                {/* End Testimonials Area */}

                {/* Start Faq Area */}
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                               <div>
                <Faq data={data} styles={styles} config={config} />
            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Faq Area */}
                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <a onClick={this.scroll} style={{"left": '35px', "right": 'unset',
                        'position': 'fixed',
                        'z-index': '500',
                        'bottom': '50px',
                        'width':' 50px',
                        'height': '50px',
                        'cursor': 'pointer',
                        'line-height': '49px',
                        'border-radius': '50%',
                        'background-color': '#f62847',
                        'text-align': 'center'
                    }}>
                        <BiCalculator />
                    </a>
                </div>
                {/* End Back To Top */}

                <Footer />
            </div>
            </div>
        )
    }
}
export default MainDemo;
