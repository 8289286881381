import React, { Component } from "react";
import {IoIosStarOutline , IoIosCog, IoIosColorWand , IoIosBarcode } from "react-icons/io";

import Sat from './sat.svg';

class TrackingTwo extends Component{
    render(){
        let title = 'Чому ми?',
        description = 'Оцініть очевидні переваги Black Car, та замовте своє омріяне авто зі Штатів.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="section-title d-flex flex-lg-row flex-sm-column flex-column mt--30 mb--50 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <img src={Sat} width={60} className="m-lg-2 m-md-2"/>
                            <div className="section-title mt--30"><h3 className="title lineheight--1">Відстежимо положення будь-якого контейнера<br/> в реальному часі</h3>
                            <p className="lineheight--1-8">Дізнайтесь номер контейнера у свого перевізника та прослідкуйте маршрут корабля, що досталяє Ваш автомобіль.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default TrackingTwo;
