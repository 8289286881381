import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { BiCalculator } from "react-icons/bi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TrackingTwo from "../elements/service/TrackingTwo";
import ServiceThree from "../elements/service/ServiceFour";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import CallAction from "../elements/callaction/CallAction";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Testimonials from "../component/HomeLayout/homeOne/Testimonials";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import CatalogWrapper from "../component/HomeLayout/homeOne/CatalogWrapper";
const data = {
    title: "FAQ (Часті запитання)",
    rows: [
        {
            title: "Що означає ''ОПТ для ВСІХ'' і як це працює?",
            content: `Ми створили рівні умови для усіх бажаючих придбати автомобіль з США, незважаючи на те, чи вам потрібен один автомобіль чи декілька.`,
        },
        {
            title: "Кому я оплачую кошти за виграний лот?",
            content:
                "Усі виграні лоти оплачуються безпосередньо в США на рахунок аукціону від вашого імені. При оплаті вказується номер лоту та VIN номер придбаного автомобіля.",
        },
        {
            title: "Чи можуть виникнути додаткові платежі після покупки лоту?",
            content: `Ми гарантуємо, що усі платежі прописані в договорі, будуть незмінними. `,
        },
        {
            title: "Чи потрібно оплачувати всю суму відразу?",
            content: "Оплата вартості вашого авто включає чотири етапи: <ul><li>	Оплата вартості виграного лоту та послуг аукціону.</li><li>	Вартість доставки автомобіля з площадки аукціону в найближчий порт + вартість морського фрахту.</li><li>	Витрати на вигрузку контейнера та доставку автомобіля в Україну з порту Клайпеда в Литві або Бремергафена в Німеччині.</li><li>	Митні платежі та послуги брокера.</li></ul>",
        },{
            title: "Чи можу я стати автомобільним дилером?",
            content: "Компанія Black Car з радістю допоможе новачкам започаткувати свою справу та стати автодилером. Ми навчимо вас усім тонкостям справи та проконсультуємо у будь-якому питанні абсолютно безкоштовно. Окрім цього, ми надамо ексклюзивні умови на придбання перших лотів для вашого нового стартапу.",
        },
        {
            title: "Чи можна доставити автомобіль в іншу область?",
            content: "Так, можемо доставити автомобіль на будь-яку адресу.",
        }
    ],
};
const styles = {
    bgColor: 'transparent',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentPaddingTop: "30px",
    rowContentPaddingBottom: "30px",
    rowContentColor: 'grey',
    arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
};
class MainDemo extends Component{
    constructor(props){
        super(props);
        this.state = {
            posts: [],
        };
        this.blackCalc = React.createRef();
        this.innerRef = React.createRef();
    }

    scroll = () => {
        let bc = this.blackCalc.current.offsetTop;
        let mb = this.innerRef.current.offsetTop;
        if (window.innerWidth > 600) {
            window.scrollTo({top: bc, behavior: 'smooth'});
        } else {
            window.scrollTo({top: mb-50, behavior: 'smooth'});
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        let url = ('https://blackcar.com.ua/api/pl/blog/getBlogItemsHome/');
        fetch(url)
            .then(response => response.text())
            .then((response) => {
                this.setState({ posts:JSON.parse(response)});
            }).catch((err) => {
            console.log('fetch', err)
        });
        this.setState({
            search: 'active',
        });
    }
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div>
                <div className="active-dark">
                    <Helmet pageTitle="BlackCar Lviv" />
                    <Header bc={this.blackCalc} bm={this.innerRef} headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Service Area  */}
                <div className="tracking-area ptb--120  bg_image bg_image--3" id="tracking">
                    <div className="container">
                        <TrackingTwo />
                        <iframe scrolling="no" src="https://www.cargoloop.com/Tracking.aspx" width="100%" height="500"/>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <a onClick={this.scroll} style={{"left": '35px', "right": 'unset',
                        'position': 'fixed',
                        'z-index': '500',
                        'bottom': '50px',
                        'width':' 50px',
                        'height': '50px',
                        'cursor': 'pointer',
                        'line-height': '49px',
                        'border-radius': '50%',
                        'background-color': '#f62847',
                        'text-align': 'center'
                    }}>
                        <BiCalculator />
                    </a>
                </div>
                {/* End Back To Top */}

                <Footer />
            </div>
            </div>
        )
    }
}
export default MainDemo;
