import React, { Component, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import {FaYoutube ,FaInstagram ,FaFacebookF , FaPaperPlane, FaViber, FaPhone} from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'
import {useHistory} from "react-router-dom";
import Cookies from 'js-cookie';

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/BlackCarLviv/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/blackcarlviv/'},
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCWyx3V2cF1Yvncowt-qfD8Q'},
]

class HeaderTwo extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);



    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Black Car " />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Black Car " />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Black Car " />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Black Car " />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.png" width={140} height={140} alt="Black Car " />;
        }

        return(
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['how','whyus','goods']} currentClassName="is-current" offset={-500}>
                                <li><a onClick={this.CLoseMenuTrigger} href="/sell#how">Як працюємо</a></li>
                                <li><a onClick={this.CLoseMenuTrigger} href="/sell#whyus">Чому ми?</a></li>
                                <li><a onClick={this.CLoseMenuTrigger} href="/sell#goods">Переваги</a></li>
                                <li><a onClick={this.CLoseMenuTrigger} href="/catalog">Каталог</a></li>
                                <li><a onClick={this.CLoseMenuTrigger} href="/buy">Авто з США</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner d-sm-none d-lg-flex">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="phoneheader d-sm-none d-lg-flex">
                                <a href="tel:+380688077711"><h5>+38 (068) 80 777 11</h5></a>
                        </div>
                        <div className="header-btn">
                                <Example />
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderTwo;

function Example() {

function onChange(e){
    this.setState({
      [e.target.id] : e.target.value,
    });
}
let history = useHistory();
function sendRequest(event){
       event.preventDefault();
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken') },
        body: JSON.stringify({
"phone": phoneValue,
"name": nameValue,
 })
    };
    fetch('/api/pl/home/addOrder/', requestOptions)
        .then(response => response.json())
    if (phoneValue && nameValue !== '' ){
        setShowSuccsess(prevValue => !prevValue);
        history.push('/success');
    }else{
       setShowError(prevValue => !prevValue);
   }
   }
    function handleNameChange(event) {
        setNameValue(event.target.value);
    }
    function handlePhoneChange(event) {
        setPhoneValue(event.target.value);
    }
  const [show, setShow] = useState(false);
  const [nameValue, setNameValue] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [showTel, setShowTel] = React.useState(false);
  const [showSuccess, setShowSuccsess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  return (
    <>
      <Button variant="white" className="rn-btn" onClick={() => setShow(true)}>
        Зв'яжись з нами
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Ми на зв'язку!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p>
             <div className={`d-flex socailities ${showTel === true ? 'nodisplayer': 'displayer'}`}>
			  <p>В якому месенджері Вам зручніше спілкуватись?</p>
              <a href="https://t.me/blackcaroffice" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-tg"><FaPaperPlane/> Telegram</a>
              <a href="viber://chat/?number=%2B380688077711" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-viber"><FaViber/> Viber</a>
			  <a className="text-center rn-button-style--2 btn-solid " onClick={() => setShowTel(true)}>Зателефонуйте мені</a>
			  <a class="text-center rn-button-style--2 btn-solid bg-dark border-dark mt-1" href="tel:+380688077711">+38 068 80 777 11</a>
            </div>
			 <div className={`d-flex socailities ${showTel === true ? 'displayer': 'nodisplayer'}`}>
         <p>Залиште свій номер і ми перезвонимо Вам якнайшвидше!</p>
           <form className="d-flex flex-column">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={ handleNameChange }
                                            placeholder="Ваше ім'я *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="tel"
                                            name="tel"
                                            id="phone"
                                            onChange={ handlePhoneChange }
                                            placeholder="Ваш телефон *"
                                        />
                                    </label>
                        <div className="socailities">
                                    <button className="rn-button-style--2 text-center m0auto btn-solid" onClick={sendRequest} id="mc-embedded-subscribe">Залишити заявку</button><br />
                                    <span className="rn-button-style--2 text-center mt-20 m0auto btn-outline" onClick={() => setShowTel(false)}>Інший спосіб</span>
                                    <div className={`success ${showSuccess === true ? 'displayer': 'nodisplayer'}`}> Дякуємо, ваша заявка відрпавлена</div>
                                    <div className={`error ${showError === true ? 'displayer': 'nodisplayer'}`}> Ви не заповнили всі поля</div>
                              </div>  </form></div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}


