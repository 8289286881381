import React, { Component } from "react";
import Calculator from "../../../elements/contact/Calculator";
import ModalVideo from 'react-modal-video';
class About extends Component{
      constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        let title = 'Калькулятор доставки авто з США',
        red_description = 'blackcar.com.ua',
        description = ' - ДИЛЕРСЬКІ ЦІНИ!';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail position-relative">
                                        <img className="w-100" src="/assets/images/about/about-f.jpg" alt="Service Images"/>
                                         <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='cHtsyVQMrrw' onClose={() => this.setState({isOpen: false})} />
                                         <button className="video-popup position-top-center" onClick={this.openModal}> </button>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12" ref={this.props.passedRef}>
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="title">{title}</h3>
                                        <p className="description"><span className="text-danger">{red_description}</span>{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <Calculator />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
