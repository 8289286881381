import React, {Component, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiCheck, FiX, FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import CatalogWrapper from "../component/HomeLayout/homeOne/CatalogWrapper";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import TrackingTwo from "../elements/service/TrackingTwo";
import ServiceThree from "../elements/service/ServiceFour";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import CallAction from "../elements/callaction/CallAction";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import "../dark/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Helmet from "../component/common/Helmet";
import Countdown from 'react-countdown';
import Cookies from 'js-cookie';
import {useHistory} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {FaPaperPlane, FaPhone, FaViber} from "react-icons/fa";

class CatalogDetails extends Component{

     constructor(props){
        super(props);
        this.state = {
            data: '',
            posts: [],
        };
    }

    componentDidMount(){
        let slug = this.props.match.params.slug;
        let url = ('https://blackcar.com.ua/api/pl/catalog/getCatalogItemDetails/'+slug);
        fetch(url)
            .then(response => response.text())
            .then((response) => {
                this.setState({ data: JSON.parse(response)[0]});
            }).catch((err) => {
            console.log('fetch', err)
        });
        this.setState({
            search: 'active',
        });
    }
    render(){
        let utcDate = new Date().getTime();
        let date = this.state.data.date_arriving;
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div>
            <div className="active-dark">
                <Helmet pageTitle="BlackCar Lviv" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
               </div> <div className="active-dark">
                <div className="row pt--200">
                    <div className="container">
                    </div>
                    <div className="d-flex row">
                        <div className="col-6 ml-2">
                            <Carousel showArrows={true} showThumbs={true} showIndicators={false}>
                            {this.state.data.images && this.state.data.images.map((value , index) => (
                                <div>
                            <img src={'https://blackcar.com.ua/uploads/'+value.image} key={index} />

                                </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="col-5 m-3">
                                    <div className="section-title"><h3>{this.state.data.title} <span className="text-white-50">{this.state.data.engine_volume}</span> <span className="text-white-50">{this.state.data.production_year}</span></h3></div>
                            <span className="text-white-50">VIN: {this.state.data.vin}</span><br/>
                            {this.state.data.status === "just_created" ? <span className="font-weight-light mt-5 badge-pill badge-secondary ">Щойно виграли!</span> :
                                this.state.data.status === "delivered_warehouse" ? <span className="font-weight-light mt-5 badge-pill badge-secondary ">Прямує з аукціону в порт</span> :
                                    this.state.data.status === "waiting_container" ? <span className="font-weight-light mt-5 badge-pill badge-secondary  ">Прибув в порт відправлення</span> :
                                        this.state.data.status === "container_booked" ? <span className="font-weight-light mt-5 badge-pill badge-secondary ">В черзі на завантаження</span> :
                                            this.state.data.status === "shipping" ?
                                                <p>
                                                    <span className="font-weight-light mt-5 badge-pill badge-info small">Пливе в порт призначення</span>
                                                    <span>Доставка через <b><Countdown date={this.state.data.date_arriving}/></b></span>
                                                </p>
                                                :
                                                this.state.data.status === "shipped" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">Прибув в порт призначення</span> :
                                                    this.state.data.status === "blackcar" ? <span className="font-weight-light mt-5 badge-pill badge-success small"><FiCheck className="text-white-50"/> На майданчику BlackCar</span> :
                                                        this.state.data.status === "reserved" ? <span className="font-weight-light mt-5 badge-pill badge-warning small"><FiX className="text-white-50"/> Зарезервовано</span> :
                                                            this.state.data.status === "sold" ? <span className="font-weight-light mt-5 badge-pill badge-danger small"><FiX className="text-white-50"/> Продано</span> : ''}
                            <p className="text-white mt-2">
                                {this.state.data.description}
                            </p>
                            <span className="small text-white-50">Вартість розмитненого авто в Україні</span>
                            <h3>{this.state.data.price} $</h3>
                            <Example />


                        </div>
                    </div>
                </div>
                {/* End Slider Area   */}
                <Footer />
            </div>
            </div>
        )
    }
}
export default CatalogDetails;

function Example() {

    function onChange(e){
        this.setState({
            [e.target.id] : e.target.value,
        });
    }
    let history = useHistory();
    function sendRequest(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken') },
            body: JSON.stringify({
                "phone": phoneValue,
                "name": nameValue,
            })
        };
        fetch('https://blackcar.com.ua/api/ru/home/addOrder/', requestOptions)
            .then(response => response.json())

        if (phoneValue && nameValue !== '' ){
            setShowSuccsess(prevValue => !prevValue);
            history.push('/success');
        }else{
            setShowError(prevValue => !prevValue);
        }
    }
    function handleNameChange(event) {
        setNameValue(event.target.value);
    }
    function handlePhoneChange(event) {
        setPhoneValue(event.target.value);
    }
    const [show, setShow] = useState(false);
    const [nameValue, setNameValue] = React.useState("");
    const [phoneValue, setPhoneValue] = React.useState("");
    const [showTel, setShowTel] = React.useState(false);
    const [showSuccess, setShowSuccsess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    return (
        <>
            <Button variant="white" className="rn-btn" onClick={() => setShow(true)}>
                Купити зараз
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Ми на зв'язку!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <div className={`d-flex socailities ${showTel === true ? 'nodisplayer': 'displayer'}`}>
                            <p>В якому месенджері Вам зручніше спілкуватись?</p>
                            <a href="https://t.me/blackcaroffice" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-tg"><FaPaperPlane/> Telegram</a>
                            <a href="viber://chat/?number=%2B380688077711" target="_blank" className="text-center rn-button-style--2 btn-solid btn-social-viber"><FaViber/> Viber</a>
                            <a className="text-center rn-button-style--2 btn-solid " onClick={() => setShowTel(true)}>Зателефонуйте мені</a>
			  <a class="text-center rn-button-style--2 btn-solid bg-dark border-dark mt-1" href="tel:+380688077711">+38 068 80 777 11</a>
                        </div>
                        <div className={`d-flex socailities ${showTel === true ? 'displayer': 'nodisplayer'}`}>
                            <p>Залиште свій номер і ми перезвонимо Вам якнайшвидше!</p>
                            <form className="d-flex flex-column">
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        onChange={ handleNameChange }
                                        placeholder="Ваше ім'я *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="tel"
                                        name="tel"
                                        id="phone"
                                        onChange={ handlePhoneChange }
                                        placeholder="Ваш телефон *"
                                    />
                                </label>
                                <div className="socailities">
                                    <button className="rn-button-style--2 text-center m0auto btn-solid" onClick={sendRequest} id="mc-embedded-subscribe">Залишити заявку</button><br />
                                    <span className="rn-button-style--2 text-center mt-20 m0auto btn-outline" onClick={() => setShowTel(false)}>Інший спосіб</span>
                                    <div className={`success ${showSuccess === true ? 'displayer': 'nodisplayer'}`}> Дякуємо, ваша заявка відрпавлена</div>
                                    <div className={`error ${showError === true ? 'displayer': 'nodisplayer'}`}> Ви не заповнили всі поля</div>
                                </div>  </form></div>
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

