import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/FooterTwo";
import ServiceList from "../elements/service/ServiceList";
import TabTwo from "../elements/tab/TabTwo";
import Faq from "react-faq-component";
import SliderOne from "../component/slider/SliderTwo";
import ServiceTwo from "../elements/service/ServiceThree";
import CounterOne from "../elements/counters/CounterTwo";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

const data = {
    title: "FAQ (Часті запитання)",
    rows: [
        {
            title: "Який документ ви надаєте про те, що я залишив свій автомобіль у вас?",
            content: `При поступленні автомобіля до нас на площадку, менеджер компанії разом із власником автомобіля складають акт прийому ТЗ на комісію в якому фіксується дата поступлення, комплектність, оцінка технічного та візуального стану автомобіля. Сформований акт прийому також підтверджується фото матеріалами.`,
        },
        {
            title: "Як швидко ви зможете продати мій автомобіль?",
            content:
                "На швидкість продажу автомобіля впливають декілька чинників, а саме <ul><li>Технічний та візуальний стан автомобіля</li><li>Попит на дану модель та кількість пропозицій на ринку</li><li>Вартість автомобіля</li><li>Якість фото та відео матеріалів</li></ul>Після того, як ви привезете своє авто до нас на площадку, ми оцінимо усі чинники та визначимо чи відповідає стан автомобіля заявленій вами вартості. Також підкажемо і, при бажанні клієнта, допоможемо надати вашому автомобілю хороший товарний вигляд. Це дозволить виділити ваш автомобіль серед конкурентів і, як результат, вигідно та швидко продати його.",
        },
        {
            title: "Чи може хтось пошкодити мій автомобіль на площадці?",
            content: `З моменту поступлення до нас на площадку ваш автомобіль знаходиться в повній безпеці. Усе, що відбувається на території паркінгу, фіксується на відео 24/7. Тест драйв і огляд автомобіля завжди проводиться в присутності менеджера нашої компанії. Ми гарантуємо бережне відношення, щоб ви могли не турбуватись про безпеку авто, поки ми займаємось його продажем. `,
        }
    ],
};
const styles = {
     bgColor: 'transparent',
     titleTextColor: "black",
     rowTitleColor: "black",
     rowContentPaddingTop: "30px",
     rowContentPaddingBottom: "30px",
     rowContentColor: 'black',
     arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
};
class ComisDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div>
            <div className="active-dark">
                <Helmet pageTitle="BlackCar Lviv" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
               </div> <div className="slider-wrapper" id="home">
                    <SliderOne />
                </div><div className="">
                {/* End Slider Area   */}

               {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1" id="how">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-8.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title">Як ми швидко продаємо авто?</h3>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_image bg_image--3" id="whyus">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area  */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">

                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}


            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" id="goods" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">І все?</h2>
                                    <p>Ні, не все! Ось ще переваги від Black Car:</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

                {/* Start Faq Area */}
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                               <div>
                <Faq data={data} styles={styles} config={config} />
            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Faq Area */}
                {/* Start Back To Top */}
                <div className="backto-top" id="contactus">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </div>
            </div>
        )
    }
}
export default ComisDemo;