import React, { Component } from "react";
import Slider from "react-slick";
import axios from 'axios';
import { portfolioSlick2 } from "../../../page-demo/script";
import { FiCheck, FiX } from "react-icons/fi";
import Countdown from 'react-countdown';

class CatalogWrapper extends Component{
     constructor(props){
        super(props);
        this.state = {
            contacts: '',
        }
     }

componentDidMount() {
		fetch("https://blackcar.com.ua/api/pl/catalog/getCatalogItems/")
		.then(response => response.text())
        .then((response) => {
            this.setState({ contacts: JSON.parse(response)});
        }).catch((err) => {
            console.log('fetch', err)
    	});
}

render(){
        let utcDate = new Date().getTime();
        let mylist = Array.from(this.state.contacts);
        let urlstart = "https://blackcar.com.ua"
        return(
            <React.Fragment>
                    <div className="mb-5">
                <div className="d-flex flex-row flex-wrap mt-1">
                            {mylist.map((value , index) => (
                               <div className="col-lg-3 mt-2">
                                   <a href={'catalog/'+value.base_name}>
                                   <img src={urlstart+value.main_image}/>
                                   </a>
                                   <div className="content">
                                       <div className="inner">
                                           <h5 className="mt-2"> {value.title} <span className="text-white-50 font-weight-light">{value.engine_volume}</span> <span className="text-white-50">{value.production_year}</span><br/>

                                           {value.status === "just_created" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">Щойно виграли!</span> :
                                               value.status === "delivered_warehouse" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">Прямує з аукціону в порт</span> :
                                                   value.status === "waiting_container" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">Прибув в порт відправлення</span> :
                                                      value.status === "container_booked" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">В черзі на завантаження</span> :
                                                          value.status === "shipping" ?
                                                              <p>
                                                              <span className="font-weight-light mt-5 badge-pill badge-info small"> Пливе в порт призначення</span>
                                                              <span>Доставка через <b><Countdown date={value.date_arriving}/></b></span>
                                                              </p>
                                                            :
                                                             value.status === "shipped" ? <span className="font-weight-light mt-5 badge-pill badge-secondary small">Прибув в порт призначення</span> :
                                                                value.status === "blackcar" ? <span className="font-weight-light mt-5 badge-pill badge-success small"><FiCheck className="text-white-50"/> На майданчику BlackCar</span> :
                                                                    value.status === "reserved" ? <span className="font-weight-light mt-5 badge-pill badge-warning small"><FiX className="text-white-50"/> Зарезервовано</span> :
                                                                        value.status === "sold" ? <span className="font-weight-light mt-5 badge-pill badge-danger small"><FiX className="text-white-50"/> Продано</span> : <span className="font-weight-light mt-5 badge-pill badge-secondary small" style={{opacity:0}}></span>}
                                           </h5>
                                           <span className="small text-white-50">Вартість розмитненого авто в Україні</span>
                                           <h3> {value.price} $</h3>
                                       </div>
                                   </div>
                               </div>
                            ))}
                </div>
                    </div>

            </React.Fragment>
        )
    }
}
export default CatalogWrapper;
